import React, { useEffect, useContext, useRef, useState, useCallback } from "react"
import * as bodyScrollLock from "body-scroll-lock"
import OptionSelector from "@src/components/product/option-selector"
import { getRegionHandle, getRegionShopUrl, isVisibleIn } from "@utils/region"
import StoreContext from "@context/store-context"
import {
  getAccumulatedPrice,
  getPrice,
} from "@utils/products/pricing"
import { getUriFromDocument } from "@utils/routing"
import ScreenReaderText from "@components/screen-reader-text"
import { getAtcLabels } from "@utils/general"
import { useProductQuickView } from "@context/product-quick-view-context"
import { clearAllBodyScrollLocks } from "body-scroll-lock"
import { isEqual } from "lodash"
import Button from "@components/button"
import { ReactComponent as Close } from "@svgs/close.svg"
import ImageCarousel from "../gallery"
import ReadMore from "@components/read-more"
import QuantityInput from "@components/quantity-input"
import KlaviyoBackInStock from "../../newsletter/back-in-stock"
import { Link, navigate } from "gatsby"
import ShippingConditions from "@components/product/shipping-conditions"
import { trackAddToCart, trackEvent, trackSelectItem } from "@src/services/analytics"
import { getImageUrl } from "@src/utils/image"
import { trackTripleWhaleAddToCart } from "@src/utils/product"


const ProductQuickView = () => {
  const {
    open,
    type,
    quickViewProduct: product,
    quickViewVariant: variant,
    setQuickViewOpen,
    setQuickViewVariant,
    addedCrossSellProducts,
    setAddedCrossSellProducts,
    quickViewProductList,
  } = useProductQuickView()
  
  if (!product || !open) return <></>

  return (
    <ProductQuickViewInner 
      product={product}
      open={open}
      type={type}
      variant={variant}
      setQuickViewOpen={setQuickViewOpen}
      setQuickViewVariant={setQuickViewVariant}
      addedCrossSellProducts={addedCrossSellProducts}
      setAddedCrossSellProducts={setAddedCrossSellProducts}
      quickViewProductList={quickViewProductList}
    />
  )
}

const ProductQuickViewInner = ({
  product,
  open,
  type,
  variant,
  setQuickViewOpen,
  setQuickViewVariant,
  addedCrossSellProducts,
  setAddedCrossSellProducts,
  quickViewProductList,
}) => {
  const {
    addLineItemsToCart,
    toggleDrawer,
    store: { client, adding },
  } = useContext(StoreContext)
  const popupRef = useRef()
  const [quantity, setQuantity] = useState(1)
  useEffect(() => {
    if (popupRef.current) {
      if (open) {
        bodyScrollLock.disableBodyScroll(popupRef.current)
        popupRef.current.focus();
  
        bodyScrollLock.enableBodyScroll(popupRef.current)
    }
  }
  },[open])

  const handleClose = () => {
  

  setQuickViewOpen(false)
    clearAllBodyScrollLocks()
    
  }

  const { _type, title, summary, slug, isBundle, productGroups } = product
  const { _id, shopifyId } = variant
  const productUrl =
    getUriFromDocument({ slug, productGroups, _type }) +
    (!isBundle ? "?variantId=" + (_id || shopifyId) : "")

  const handleAddToCart = async (product, variant) => {
    const productVariant =
      client.product.helpers.variantForOptions(product, variant) || variant
    let lineItems = [];
    const lineItemsData = [];
    if (productVariant.variantComponents?.length > 0) {
      const componentId = Math.random()

      productVariant.variantComponents.forEach(variantComponent => {
        const { shopifyIdBase64Encoded, status } = variantComponent
        lineItemsData.push({
          merchandise: {
            id: shopifyIdBase64Encoded,
            sku: variantComponent.sku,
            title: variantComponent.title || variantComponent.variantTitle,
            price: variantComponent.price,
            compareAtPrice: variantComponent.compareAtPrice,
            image: {
              src: getImageUrl(variantComponent.listingImage),
            },
            product: {
              id: product.shopifyId,
              title: product.title,
              category: product?.productGroups?.map(group => group.title),
              list: quickViewProductList || getUriFromDocument(product?.productGroups[0]),
              onlineStoreUrl: getRegionShopUrl() + getUriFromDocument(product)
            },
          },
          quantity,
        })

        lineItems.push({
          merchandiseId: shopifyIdBase64Encoded,
          attributes: {
            _preorder: status?.preorder ? "true" : "false",
            _backorder: status?.backorder ? "true" : "false",
            _region: getRegionHandle(),
            _componentOf: componentId.toString(36),
            _category: product?.productGroups?.map(group => group.title).join(', '),
            _list: quickViewProductList || getUriFromDocument(product?.productGroups[0]),
          },
          quantity,
        })
      })
    } else {
      lineItemsData.push({
        merchandise: {
          id: productVariant.shopifyIdBase64Encoded,
          sku: productVariant.sku,
          title: productVariant.title || productVariant.variantTitle,
          price: productVariant.price,
          compareAtPrice: productVariant.compareAtPrice?.toFixed(2),
          image: {
            src: getImageUrl(productVariant.listingImage || productVariant.variantImages[0]),
          },
          product: {
            id: product.shopifyId,
            title: product.title,
            category: product?.productGroups?.map(group => group.title),
            list: quickViewProductList || getUriFromDocument(product?.productGroups[0]),
            onlineStoreUrl: getRegionShopUrl() + window.location.pathname
          },
        },
        quantity,
      });

      lineItems.push(
        {
          merchandiseId: productVariant.shopifyIdBase64Encoded,
          attributes: {
            _preorder: productVariant.status?.preorder ? "true" : "false",
            _backorder: productVariant.status?.backorder ? "true" : "false",
            _region: getRegionHandle(),
            _category: product?.productGroups?.map(group => group.title).join(', '),
            _list: quickViewProductList || getUriFromDocument(product?.productGroups[0]),
          },
          quantity,
        },
      )
    }
    await addLineItemsToCart(lineItems)
    handleClose()

    trackAddToCart(lineItemsData, "Quick View Modal")

    trackTripleWhaleAddToCart(variant._id);
  }

  const handleSelectItem = () => {
    trackSelectItem([{
      merchandise: {
        id: variant.shopifyId,
        sku: variant.sku,
        title: variant.variantTitle,
        price: variant.price.toFixed(2),
        compareAtPrice: variant.compareAtPrice?.toFixed(2),
        image: {
          src: '',
        },
        product: {
          id: product.shopifyId,
          title: product.title,
          category: product?.productGroups?.map(group => group.title),
          list: "Quick View Modal" || getUriFromDocument(product?.productGroups[0]),
          onlineStoreUrl: getRegionShopUrl() + getUriFromDocument(product)
        },
      },
      quantity,
    }], "Quick View Modal")
  }

  const price = getPrice(variant)

  const accumulatedPrice = getAccumulatedPrice(variant, quantity, addedCrossSellProducts);

  // const discountDisplay = getDiscountDisplay(
  //   product.discountDisplayPercentage,
  //   variant,
  //   product?.priceRange?.minVariantPrice
  // )
  // const allPrices = { price, compareAtPrice, discountDisplay }

  const isProductInRegion =
    isVisibleIn(product.visibilityRegions) && (isVisibleIn(variant.visibilityRegions) || variant.isAvailableInRegion )

  // const unavailable = !variant.availableForSale || adding || !isProductInRegion

  const additionalHandleOptionChange = (optionIndex, { target }) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    if (currentOptions[optionIndex].value !== value) {
      currentOptions[optionIndex] = {
        ...currentOptions[optionIndex],
        value,
      }

      const selectedVariant = product?.variants.find(({ selectedOptions }) =>
        isEqual(currentOptions, selectedOptions)
      )
      setQuickViewVariant({ ...selectedVariant })
    }
  }

  const handleCrossSell = () => {
    const selectedVariant = {
      ...variant,
      product: {
        ...product,
        id: product.shopifyId,
        title: product.title,
        category: product?.productGroups?.map(group => group.title),
        onlineStoreUrl: getRegionShopUrl() + getUriFromDocument(product),
      },
      attributes: {
        _preorder: status?.preorder ? "true" : "false",
        _backorder: status?.backorder ? "true" : "false",
        _region: getRegionHandle(),
        // _componentOf: componentId.toString(36),
        _category: product?.productGroups?.map(group => group.title).join(', '),
        _list: quickViewProductList || getUriFromDocument(product?.productGroups[0]),
      },
      quantity,
    }

    setAddedCrossSellProducts(prevState =>
      !addedCrossSellProducts?.find(crossSell => crossSell?.shopifyId === selectedVariant?.shopifyId)
        ? [...prevState, { product: selectedVariant, quantity: quantity }] 
        : [...prevState]
    )
  }

  const [available, setAvailable] = useState(variant.availableForSale)
  const [atcLabel, setAtcLabel] = useState('');

  useEffect(() => {
    setAvailable(variant.availableForSale);

    const fetchLabel = async () => {
      try {
        const result = await getAtcLabels(variant);
        setAtcLabel(result);
      } catch (error) {
        console.error("Error fetching label:", error);
      }
    };

    fetchLabel();
  }, [variant])

  const removeProduct = () => {
    setAddedCrossSellProducts(prevState =>
      prevState.filter(crossSellProduct => !product?.variants?.map(variant => variant.shopifyIdBase64Encoded).includes(crossSellProduct.product.shopifyIdBase64Encoded))
    )
  }

  const trackUpsell = () => {
    trackEvent("upsell_add_to_order", {});
  }

  return (  
    <div className={`w-full fixed top-0 right-0 bottom-0 left-0 flex z-[90003] items-center justify-center ${open ? `` : `hidden`}`} role="dialog" aria-labelledby="productDetailsPopupHeading">
      <div onClick={handleClose} className="fixed top-0 left-0 z-10 w-full h-full bg-black opacity-25" ></div>
      <div ref={popupRef} className="relative text-left z-20 max-w-4xl m-5 quick-view-modal bg-white overflow-x-hidden overflow-y-auto rounded-xl" tabIndex={0}>
        <div className="w-full flex justify-between items-center md:p-10 md:pb-6 sticky top-0 bg-white z-[51]">
          <p className="subheadline font-medium max-md:hidden max-md:invisible" id="productDetailsPopupHeading" >
            <ScreenReaderText
              srText={`Product Title: ${title}`}
              defaultText={title}
            />
          </p>
          <div className="flex items-center justify-between">
          <button 
            type="button" 
            aria-label="Close Quick View Modal" 
            onClick={handleClose} 
            className="hover:bg-light-gray p-4 duration-300 shadow-md rounded-full cursor-pointer z-50 bg-white max-md:absolute max-md:right-5 max-md:top-5"
          >
            <Close className="w-4" focusable="false" />
          </button>
          </div>
        </div>
        <div className="h-full grid grid-cols-12 gap-y-5 md:gap-x-10 bg-white md:px-10">
          <div className="col-span-12 md:col-span-7 flex flex-col gap-y-5 max-md:px-5 max-md:order-1">
            <p className="subheadline font-medium min-md:hidden min-md:invisible" id="productDetailsPopupHeading" >
              <ScreenReaderText
                srText={`Product Title: ${title}`}
                defaultText={title}
              />
            </p>

            {summary && 
              <ReadMore text={summary} />}

            {["product", "cross-sell"].includes(type) && (
              <>
                <OptionSelector
                  {...{
                    currentVariant: variant,
                    productOptions: product.productOptions,
                    variants: product.variants,
                    handleOptionChange: additionalHandleOptionChange,
                  }}/>
                <ShippingConditions {...{ productEntry: product, currentEntryVariant: variant }} className="md:pb-2.5 max-md:pt-2.5" />
              </>
            )}

            {type === "details" && (
              <Button
                to={productUrl}
                title={title}
                className="btn btn-gray btn-soft-black hover:btn-gray mt-auto"
                inline={false}
                target="_self"
                onClick={() => {
                  navigate(productUrl)
                  handleClose()
                }}
              >
                Full Details
              </Button>
            )}
          </div>

          <div className="col-span-12 md:col-span-5 quick-view-gallery-column z-[13] max-md:px-5 max-md:pt-5">
            <ImageCarousel
              productEntry={product}
              images={variant?.variantImages}
              isQuickView={true}
              customThumbnailSliderSettings={{
                slidesToShow: 2,
              }}
            />
          </div>
        </div>
        <div className="w-full sticky pt-6 p-5 md:pb-8 md:px-8 bottom-0 z-[12] bg-white">
          {["cross-sell", "product"].includes(type) && (
            <>
              {type === "cross-sell" && (
                <div className="grid grid-cols-12 max-md:gap-y-2.5 md:gap-x-10">
                  <div className="col-span-12 md:col-span-7">
                    <div className="flex gap-2.5 md:gap-5">
                      <QuantityInput
                        currentQuantity={quantity}
                        onUpdate={e => setQuantity(e)}
                        minQuantity={1}
                      />
                      <Button
                        theme="btn-transparent-dark"
                        onClick={() => {
                          if (available && isProductInRegion) {
                            removeProduct()
                            handleCrossSell()
                            handleClose()
                            trackUpsell()
                          }
                        }}
                        disabled={!available || !isProductInRegion}
                        arrow="none"
                        className="justify-between font-normal bg-safety-green hover:bg-soft-black hover:text-white disabled:opacity-70 disabled:cursor-not-allowed"
                        ariaLabel={`Select Cross-sell product: ${title} for only ${price}`}
                        >
                        <span className="font-medium">Add to Order</span>
                        <span className="font-medium">{price}</span>
                      </Button>
                      
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-5 grid">
                    <Button
                      title={title}
                      state={{ fromList: quickViewProductList }}
                      className="btn btn-soft-black hover:bg-gray hover:text-soft-black hover:border-gray mt-auto"
                      inline={false}
                      target="_self"
                      onClick={() => {
                        navigate(productUrl)
                        handleClose()
                        handleSelectItem()
                        toggleDrawer({ override: false })
                      }}
                    >
                      Full Details
                    </Button> 
                  </div>
                </div>
              )}

              {type === "product" && (
                <div className="grid grid-cols-12 max-md:gap-y-2.5 md:gap-x-10">
                  <div className="col-span-12 md:col-span-7">
                    {available && !variant?.status?.watchlist && (
                      <div className="flex gap-2.5 md:gap-5">
                        <QuantityInput
                          currentQuantity={quantity}
                          onUpdate={e => setQuantity(e)}
                          minQuantity={1}
                        />
                        <button
                          onClick={() => {
                            if (available && isProductInRegion && !adding) {
                              handleAddToCart(product, variant)
                            }
                          }}
                          type="submit"
                          disabled={!available || !isProductInRegion || adding}
                          className="btn btn-secondary hover:bg-gray hover:text-soft-black hover:border-gray w-full disabled:opacity-70 disabled:cursor-not-allowed"
                        >
                          {adding ? "Adding" : atcLabel}
                          <span className="is-sticky-price">{accumulatedPrice}</span>
                        </button>
                      </div>
                    )}
                    {!available && (
                      <KlaviyoBackInStock data={product.klaviyo} variantId={variant?.shopifyId} />
                    )}
                  </div>
                  <div className="col-span-12 md:col-span-5 grid">
                    <Button
                      title={title}
                      state={{ fromList: quickViewProductList }}
                      className="btn btn-soft-black hover:bg-gray hover:text-soft-black hover:border-gray mt-auto"
                      inline={false}
                      target="_self"
                      onClick={() => {
                        navigate(productUrl)
                        handleClose()
                        handleSelectItem()
                        toggleDrawer({ override: false })
                      }}
                    >
                      Full Details
                    </Button>
                  </div>
                </div>
              )}      
              
              {!isProductInRegion &&
                <p className="pt-2.5">This product is not yet available in your country.</p>}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductQuickView